import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "E-Mail-Signatur",
  "description": "Hinzufügen einer persönlichen Signatur für das Versenden von E-Mails über das Portal. So gehen Sie vor.",
  "author": "Oliver Lorenz",
  "categories": ["settings"],
  "date": "2021-08-27T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sie befinden sich auf der Startseite.`}</p>
    <img src="/images/settings/signature01.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Dort klicken Sie auf "Mein Konto".`}</p>
    <img src="/images/settings/signature02.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Hier scollen Sie nach unten bis Sie den Bereich "E-Mail-Signatur" finden.`}</p>
    <img src="/images/settings/signature03.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Im Eingabfeld des Editors können Sie Ihre persönliche Signatur eintragen. `}</p>
    <img src="/images/settings/signature04.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Zum Speichern müssen Sie auf der selben Seite nach unten scrollen und auf "Benutzerdaten aktualisieren" klicken. `}</p>
 <img src="/images/settings/signature05.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Ihnen wird nun bestätigt, dass die Speicherung erfolgreich war. `}</p>
    <img src="/images/settings/signature06.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Sie können nun einen Bewerber aufrufen und über den Button "E-Mail senden" die Email Funktion aufrufen.`}</p>
    <img src="/images/settings/signature07.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Es öffnet sich eine neue Seite zum E-Mail schreiben in einem neuen Fenster.  `}</p>
    <p>{`Ihre persönlich erstellte Signatur wurde im Editor automatisch eingefügt. `}</p>
    <img src="/images/settings/signature08.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Hinweis: Ihre Absender E-Mail Adresse sowie die Empfänger E-Mail Adresse des Bewerbers werden automatisch eingefügt und können hier nicht verändert werden.`}</p>
    <p>{`Im Bereich "Vorlage" können Sie eine Ihrer hinterlegten Vorlagen auswählen. `}</p>
    <p>{`(Zum nachlesen, wie eine Vorlage erstellt wird können Sie diesen Link öffnen `}<a parentName="p" {...{
        "href": "http://help.inriva.com/de-form/articles/4479478-e-mail-vorlagen"
      }}>{`http://help.inriva.com/de-form/articles/4479478-e-mail-vorlagen`}</a>{`)`}</p>
    <img src="/images/settings/signature09.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Ihre ausgewählte Vorlage erscheint über Ihrer Signatur im Editor.`}</p>
    <img src="/images/settings/signature10.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Über "E-Mail senden" verschicken Sie Ihre E-Mail. Gleichzeitig wird das Fenster geschlossen und Sie werden zurück auf die Bewerberseite geleitet, dort erscheint nun die Mitteilung "Die E-Mail wurde verschickt!".`}</p>
    <img src="/images/settings/signature11.png" alt="email signatur einrichten" style={{
      "width": "100%"
    }} />
    <p>{`Ihre E-Mail kommt beim Empfänger genau so an, wie Sie diese im Editor gestaltet haben.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      